import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Oferta salonu kosmetycznego w Olsztynie | Gabinet Elżbieta</title>
    <meta name="description" content="Poznaj ofertę Gabinetu Kosmetycznego Elżbieta - szeroki wybór zabiegów takich jak elekroliza, Dermapen, peeling kawitacyjny, Sonoforeza, Retix C i wiele innych. Skorzystaj z profesjonalnej pielęgnacji dostosowanej do Twoich potrzeb i ciesz się pięknem każdego dnia." />
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const ListOffer = styled.ul`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 25px;
  list-style: none;
  padding: 0;

  @media only screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    display: block;
    position: relative;

    &:hover,
    &:focus {
      &::before {
        opacity: 1;
      }
    }

    &:before {
      transition: opacity 150ms ease-in;
      background: rgba(35,35,35,.7);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  li {
    &.disabled {
      cursor: not-allowed;

      a {
        pointer-events: none;
        opacity: .5;
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }

  span {
    color: #333;
    background-color: rgba(255,255,255,.9);
    border-left: 4px solid ${({ theme }) => theme.primaryLight};
    bottom: 0;
    left: 0;
    padding: 10px 15px;
    position: absolute;
    z-index: 2;
  }
`;

const Voucher = styled.div`
  margin: 0 0 5rem;
  text-align: center;


  .heading {
    margin: 0 0 3rem;

    &__title {
      font-size: 3.2rem;
      font-weight: 600;
      margin: 0;

      @media only screen and (min-width: 1024px) {
        font-size: 4.2rem;
      }
    }

    &__subtitle {
      font-size: 1.8rem;
    }
  }


  a {
    display: inline-block;
    transition: opacity 200ms ease-in;

    figure {
      margin: 0;
    }

    &:hover,
    &:focus {
      opacity: .75;
    }
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">

        <Voucher>
          <div className="heading">
            <p className="heading__title">Voucher upominkowy</p>
            <p className="heading__subtitle">Na zabieg lub na dowolną kwotę</p>
          </div>

          <Link to="/cennik/pomysl-na-prezent/" title="Zamów kartę podarunkową na nasze zabiegi">
            <figure>
              <GatsbyImage
                image={getImage(data.voucher)}
                alt="Voucher podarunkowy"
              />
            </figure>
          </Link>
        </Voucher>

        <p className="subtitle">OFERTA</p>
        <h1>Zabiegi</h1>

        <ListOffer>
          <li>
            <Link to="/oferta/elektroliza/">
              <span>Elektroliza - 0,90 zł za impuls</span>
              <GatsbyImage
                image={getImage(data.elektroliza)}
                alt="Elektroliza"
                loading="eager"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/piercing-uszu/">
              <span>Piercing - od 49 zł</span>
              <GatsbyImage
                image={getImage(data.piercing)}
                alt="Piercing"
                loading="eager"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/kosmetyka-mezczyzn/">
              <span>Kosmetyka mężczyzn - od 120 zł</span>
              <GatsbyImage
                image={getImage(data.kosmetyka_mezczyzn)}
                alt="Kosmetyka mężczyzn"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/dermapen/">
              <span>Dermapen - od 200 zł</span>
              <GatsbyImage
                image={getImage(data.dermapen)}
                alt="Dermapen"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/peeling-kawitacyjny/">
              <span>Peeling kawitacyjny - od 70 zł</span>
              <GatsbyImage
                image={getImage(data.peeling_kawitacyjny)}
                alt="Kawitacja"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/henna/">
              <span>Henna - od 40 zł</span>
              <GatsbyImage
                image={getImage(data.henna)}
                alt="Henna"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/mikrodermabrazja/">
              <span>Mikrodermabrazja - od 180 zł</span>
              <GatsbyImage
                image={getImage(data.mikrodermabrazja)}
                alt="Mikrodermabrazja"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/depilacja-woskiem/">
              <span>Depliacja woskiem - od 20 zł</span>
              <GatsbyImage
                image={getImage(data.depilacja_woskiem)}
                alt="Depliacja woskiem"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/sonoforeza/">
              <span>Sonoforeza - od 100 zł</span>
              <GatsbyImage
                image={getImage(data.sonoforeza)}
                alt="Ultradźwięki"
              />
            </Link>
          </li>
          <li>
            <Link to="/oferta/retix-c/">
              <span>Retix C - od 240 zł</span>
              <GatsbyImage
                image={getImage(data.retix_c)}
                alt="Retix C"
              />
            </Link>
          </li>
        </ListOffer>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    elektroliza: file(relativePath: {regex: "/offer\/elektroliza.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    piercing: file(relativePath: {regex: "/offer\/piercing.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    depilacja_laserowa: file(relativePath: {regex: "/offer\/depilacja-laserowa.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    kosmetyka_mezczyzn: file(relativePath: {regex: "/offer\/kosmetyka-mezczyzn.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    dermapen: file(relativePath: {regex: "/offer\/dermapen.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    peeling_kawitacyjny: file(relativePath: {regex: "/offer\/peeling-kawitacyjny.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    henna: file(relativePath: {regex: "/offer\/henna.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    mikrodermabrazja: file(relativePath: {regex: "/offer\/mikrodermabrazja.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    depilacja_woskiem: file(relativePath: {regex: "/offer\/depilacja-woskiem.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    sonoforeza: file(relativePath: {regex: "/offer\/sonoforeza.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    retix_c: file(relativePath: {regex: "/offer\/retix-c.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    voucher: file(relativePath: {regex: "/home\/voucher-podarunkowy.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 500
        )
      }
    },
  }`;

export default IndexPage;
