/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import loadable from '@loadable/component';
// import Testimonials from '../components/Home/Testimonials';

export const Head = () => (
  <>
    <title>Gabinet kosmetyczny Elżbieta - Olsztyn</title>
    <meta name="description" content="Gabinet Kosmetyczny Elżbieta - profesjonalne zabiegi kosmetyczne w Olsztynie. Oferujemy zabiegi: elektrolizy, przekłuwanie uszu, depilację woskiem, mikrodermabrazję i wiele innych. Zadbaj o swoje piękno i dobre samopoczucie z nami! Zarezerwuj wizytę już dziś." />
    <link rel="canonical" href="https://gabinetelzbieta.pl" />
  </>
);

const Testimonials = React.lazy(() => import('../components/Home/Testimonials'));

const renderLoader = () => <p style={{ textAlign: 'center' }}>Loading...</p>;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Main = styled.main`
  margin-bottom: 0;
  
  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
`;

const Welcome = styled.section`
  .gatsby-image-wrapper {
    margin: 20px 0 30px;

    @media only screen and (min-width: 960px) {
      margin: 0 0 30px;
    }
  }
`;

const Treatments = styled.section`
  h2 {
    margin: 0 0 5rem;
    text-align: center;
  }
`;

const Services = styled.ul`
  display: grid;
  grid-gap: 0;
  list-style: none;
  padding: 0;

  h3 {
    font-size: 2.5rem;
    margin-top: 0;

    a {
      transition: color 150ms ease-in, border-color 150ms ease-in;
      color: inherit;
      border-bottom: 2px solid rgba(179, 0, 39,.25);
      text-decoration: none;

      &:hover,
      &:focus {
        border-bottom-color: ${({ theme }) => theme.primary};
        /* color: ${({ theme }) => theme.primary}; */
        text-decoration: none;
      }
    }
  }

  figure {
    margin: 0 0 25px;
  }

  li {
    border-top: 1px solid #ccc;
    padding: 3rem 0;
    text-align: left;

    &:first-child {
      border-top: 0;
    }
  }

  .icon {
    height: 147px;
    width: 147px;
    margin: 0 auto 10px;
  }

  svg path {
    fill: ${({ theme }) => theme.primary}
  }

  ${({ theme }) => theme.mqTablet} {
    li {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      figure {
        margin: 0;
        width: 50%;
      }

      p {
        margin: 0 0 2.5rem;
      }

      .service__content {
        padding: 15px 45px;
        width: 50%;
      }

      &:nth-child(even) {
        figure {
          order: 1;
        }

        .service__content {
          padding-right: 50px;
        }
      }
    }
  }

  ${({ theme }) => theme.mqDesktop} {
    li {
      padding: 5rem 0;

      h3 {
        font-size: 3rem;
      }
    }
  }
`;

const Voucher = styled.section`
  margin: 0 0 10rem;

  h2 {
    margin: 0 0 5rem;
    text-align: center;
  }

  figure {
    margin: 70px 0;
    text-align: center;
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>
    <Main>
      <Welcome className="box" style={{ paddingTop: '0' }}>
        <div className="row">
          <p className="subtitle">ZAPRASZAMY</p>
          <h1>Najlepszy gabinet kosmetyczny w&nbsp;Olsztynie</h1>

          <div className="col">
            <div className="col--6">
              <p>
                Gabinet kosmetyki leczniczej Elżbieta to wyjątkowe miejsce, gdzie w&nbsp;komfortowych warunkach przeprowadzamy zabiegi odmładzające.
              </p>
              <p>
                Wysoka jakość naszej pracy została doceniona przez pismo <em>„Uroda Beauty Expert”</em>. Magazyn, uznając nasz wyjątkowy profesjonalizm i&nbsp;prokliencką postawę, wyróżnił nas tytułem <Link to="/o-gabinecie/wyroznienie/">gabinetu kosmetycznego godnego zaufania</Link> oraz polecenia.
              </p>

              <p>Klientom staramy się zapewnić jak najbardziej komfortowe warunki. Odwiedzając nasz gabinet, zyskujecie pewność wyjątkowej obsługi. Możecie liczyć na pełne odprężenie w&nbsp;klimatyzowanych pomieszczeniach przy relaksacyjnej muzyce w&nbsp;miłej atmosferze.</p>
            </div>

            <div className="col--6 text-center">
              <GatsbyImage
                image={getImage(data.gabinet)}
                alt="Gabinet Elżbieta"
              />
            </div>
          </div>

          <p>Gwarantujemy również, że wszystkie zabiegi realizowane są przez profesjonalistów w&nbsp;odpowiednio do tego przygotowanych, aseptycznych warunkach. Dzięki temu odwiedzając nasz gabinet, możecie mieć pewność pełnego bezpieczeństwa.</p>

          <p>Posiadamy szeroki wachlarz różnego rodzaju zabiegów, które przywrócą Waszej skórze zdrowy i&nbsp;piękny wygląd. Oferujemy naszym klientom odmładzanie skóry żelazkiem przeciwzmarszczkowym. Jest to urządzenie wyposażone w&nbsp;specjalną pompę, która pobudza skórę i mięśnie.</p>

          <div className="col col--invert">
            <div className="col--6">
              <p>
                Oczyszczamy również skórę za pomocą zabiegów
                {' '}
                <Link to="/oferta/mikrodermabrazja/">mikrodermabrazji</Link>
                {' '}
                oraz
                {' '}
                <Link to="/oferta/peeling-kawitacyjny/">peelingu kawitacyjnego</Link>
                .
                {' '}
                <Link to="/oferta/">Naszą ofertę</Link>
                {' '}
                uzupełniają zabiegi usuwania owłosienia za pomocą
                {' '}
                <Link to="/oferta/elektroliza/">elektrolizy</Link>
                .
              </p>

              <p>
                Panie odwiedzające nasz gabinet mogą skorzystać z&nbsp;naszej propozycji usług kosmetycznych, które obejmują m.in. pedicure, manicure oraz przedłużanie rzęs.
              </p>

              <p>
                Wykonujemy również
                {' '}
                <Link to="/oferta/piercing-uszu/">usługi przekłuwania uszu</Link>
                {' '}
                . Mamy także specjalną ofertę
                {' '}
                <Link to="/oferta/kosmetyka-mezczyzn/">zabiegów kosmetycznych dla&nbsp;Panów</Link>
                .
              </p>
            </div>
            <div className="col--6 text-center">
              <GatsbyImage
                image={getImage(data.gabinet_zabiegowy)}
                alt="Gabinet Elżbieta"
              />
            </div>
          </div>

        </div>
      </Welcome>

      <Treatments className="box">
        <div className="row">
          <h2>Zapraszamy na zabiegi</h2>

          <Services>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.elektroliza)}
                  alt="Elektroliza"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/elektroliza/">Elektroliza - usuwanie owłosienia</Link>
                </h3>
                <p>Jest to zabieg usuwania nadmiernego i&nbsp;niechcianego owłosienia. Niszczy całkowicie cebulkę włosa bezpowrotnie. Jest to jedyna, najskuteczniejsza na&nbsp;świecie metoda wyniszczania włosów...</p>
                <Link to="/oferta/elektroliza/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.piercing)}
                  alt="Elektroliza"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/piercing-uszu/">Przekłuwanie uszu</Link>
                </h3>
                <p>Zabieg wykonujemy urządzeniem Studex. Metoda ta&nbsp;jest całkowicie bezpieczna i&nbsp;sterylna. Kolczyki używane do&nbsp;zabiegu nie powodują uczuleń i&nbsp;podrażnień. Przyspieszają gojenie...</p>
                <Link to="/oferta/piercing-uszu/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.kosmetyka_mezczyzn)}
                  alt="Kosmetyka mężczyzn"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/kosmetyka-mezczyzn/">Kosmetyka mężczyzn</Link>
                </h3>
                <p>Zabiegi kosmetyczne dla mężczyzn w&nbsp;naszym gabinecie kosmetycznym, pomogą odpowiednio zadbać o&nbsp;skórę twarzy. Pomożemy również dobrać kosmetyki do&nbsp;pielęgnacji domowej...</p>
                <Link to="/oferta/kosmetyka-mezczyzn/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.dermapen)}
                  alt="Dermapen"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/dermapen/">Dermapen</Link>
                </h3>
                <p>Czyli inaczej mezoterapia igłowa wykonywana aparatem z&nbsp;kilkunastoma mikroigłami, zabieg pobudza kolagen i&nbsp;elastynę...</p>
                <Link to="/oferta/dermapen/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.peeling_kawitacyjny)}
                  alt="Peeling kawitacyjny"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/peeling-kawitacyjny/">Peeling kawitacyjny</Link>
                </h3>
                <p>Polega na oczyszczaniu skóry za pomocą fali ultradźwiękowej. Zabieg ten jest często stosowany w&nbsp;celu poprawy wyglądu skóry i&nbsp;usunięcia zanieczyszczeń, nadmiaru sebum oraz martwych komórek naskórka...</p>
                <Link to="/oferta/peeling-kawitacyjny/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.henna)}
                  alt="Henna brwi"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/henna/">Henna brwi</Link>
                </h3>
                <p>Jest stosowana w&nbsp;celu podkreślenia kształtu brwi i&nbsp;nadaniu im głębszego koloru. Polega na pokryciu brwi specjalną hennową pastą, która po wyschnięciu tworzy ciemny film na skórze...</p>
                <Link to="/oferta/henna/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.mikrodermabrazja)}
                  alt="Mikrodermabrazja"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/mikrodermabrazja/">Mikrodermabrazja</Link>
                </h3>
                <p>Polega na mechanicznym oczyszczaniu skóry za pomocą specjalnego urządzenia złożonego z&nbsp;głowicy z&nbsp;mikrokryształami i&nbsp;podciśnieniem...</p>
                <Link to="/oferta/mikrodermabrazja/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.depilacja_woskiem)}
                  alt="depilacja woskiem"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/depilacja-woskiem/">Depilacja woskiem</Link>
                </h3>
                <p>Zabieg ten jest jednym z najbardziej popularnych sposobów na usuwanie niechcianego owłosienia. Jest to metoda bezpieczna i skuteczna, która pozwala na długotrwałe efekty...</p>
                <Link to="/oferta/depilacja-woskiem/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.sonoforeza)}
                  alt="sonoforeza"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/sonoforeza/">Sonoforeza</Link>
                </h3>
                <p>Jest jedną z najbardziej skutecznych metod pielęgnacji skóry. Polega ona na wykorzystaniu fali ultradźwiękowej do penetracji skóry i dostarczania składników aktywnych do jej głębszych warstw...</p>
                <Link to="/oferta/sonoforeza/" className="btn">Zobacz</Link>
              </div>
            </li>
            <li>
              <figure>
                <GatsbyImage
                  image={getImage(data.retix_c)}
                  alt="sonoforeza"
                />
              </figure>
              <div className="service__content">
                <h3>
                  <Link to="/oferta/retix-c/">Retix C</Link>
                </h3>
                <p>Jest to przyjazna dla skóry kuracja złuszczająca, odmładzająca i nawilżająca. Polega na zastosowaniu witaminy C, retinolu oraz kompleksu antyoksydantów w jednej procedurze zabiegowej...</p>
                <Link to="/oferta/retix-c/" className="btn">Zobacz</Link>
              </div>
            </li>
          </Services>
        </div>
      </Treatments>

      <Voucher>
        <div className="row">
          <h2>Voucher podarunkowy</h2>

          <p>
            Chcielibyśmy zaprosić Państwa do skorzystania z naszej wyjątkowej oferty -
            {' '}
            <Link to="/cennik/pomysl-na-prezent/" title="Pomysł na prezent - zabiegi kosmetyczne">Vouchera promocyjnego</Link>
            , który możecie wykorzystać na dowolny zabieg w&nbsp;naszym gabinecie.
          </p>

          <p>Nasz voucher to doskonały pomysł na prezent dla bliskiej osoby. Dzięki niemu można skorzystać z&nbsp;naszych usług w&nbsp;atrakcyjnej cenie i&nbsp;odetchnąć od codziennych spraw.</p>

          <p>W&nbsp;naszej ofercie znajdziecie wiele zabiegów kosmetycznych dopasowanych do indywidualnych potrzeb i&nbsp;preferencji.</p>

          <p>
            Nie czekajcie dłużej i&nbsp;skorzystajcie z&nbsp;naszej promocyjnej oferty. Voucher jest ważny przez 12 miesięcy od daty zakupu, a&nbsp;jego cena jest bardzo korzystna. Już teraz możecie
            {' '}
            <Link to="/kontakt/" title="Umów się na wizytę">zaplanować swoją wizytę</Link>
            {' '}
            u&nbsp;nas i&nbsp;cieszyć się relaksem w&nbsp;naszym gabinecie.
          </p>

          <figure>
            <GatsbyImage
              image={getImage(data.voucher)}
              alt="Voucher podarunkowy"
            />
          </figure>
        </div>
      </Voucher>

      <Suspense
        fallback={renderLoader()}
      >
        <Testimonials />
      </Suspense>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero: file(relativePath: {regex: "/home\/hero.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    gabinet: file(relativePath: {regex: "/home\/gabinet-kosmetyki-leczniczej.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    gabinet_zabiegowy: file(relativePath: {regex: "/home\/gabinet-zabiegowy.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    elektroliza: file(relativePath: {regex: "/offer\/elektroliza.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    piercing: file(relativePath: {regex: "/offer\/piercing.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    kosmetyka_mezczyzn: file(relativePath: {regex: "/offer\/kosmetyka-mezczyzn.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    dermapen: file(relativePath: {regex: "/offer\/dermapen.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 535
        )
      }
    },
    peeling_kawitacyjny: file(relativePath: {regex: "/offer\/peeling-kawitacyjny.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 535
        )
      }
    },
    henna: file(relativePath: {regex: "/offer\/henna.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 535
        )
      }
    },
    mikrodermabrazja: file(relativePath: {regex: "/offer\/mikrodermabrazja.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 535
        )
      }
    },
    depilacja_woskiem: file(relativePath: {regex: "/offer\/depilacja-woskiem.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    sonoforeza: file(relativePath: {regex: "/offer\/sonoforeza.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    retix_c: file(relativePath: {regex: "/offer\/retix-c.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 542
        )
      }
    },
    voucher: file(relativePath: {regex: "/home\/voucher-podarunkowy.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 800
        )
      }
    },
  }`;

export default IndexPage;
